import type { AsyncData } from 'nuxt/app';

export const useEntryPage = ({ error, data, status }: AsyncData<any, any>, entryKey: string = 'entry') => {
    const craftEntry = computed(() => {
        return data?.value?.[entryKey];
    });

    watchEffect(() => {
        if (status.value === 'pending') {
            return;
        }

        if (!craftEntry.value && unref(error)) {
            showError({
                statusCode: error.value?.statusCode || -1,
                statusMessage: error.value?.statusMessage || 'Internal server error',
                message: error.value?.toString(),
            });
        } else if (!craftEntry?.value) {
            showError({ statusCode: 404, statusMessage: 'Page Not Found' });
        }
    });

    const seo = computed(() => craftEntry.value?.seo);

    useEntrySeo(seo);

    return {
        craftEntry,
        seo
    };
};
